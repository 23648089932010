<template>
  <m-widget-container>
    <h4 class="pt-6 px-6 text-center">
      {{ title }}
    </h4>
    <swiper
      v-if="items.length"
      ref="swiper"
      :options="swiperOptions"
      class="pb-10 px-10"
    >
      <swiper-slide
        v-for="(item, i) in items.slice(0, 8)"
        :key="i"
      >
        <v-card
          class="px-2 py-1 ma-1 border-box"
          :color="cardColor"
          :shaped="cardShaped"
          :dark="cardDark"
          :flat="cardFlat"
        >
          <v-card-title class="m-news-slider-title subtitle-1 font-weight-bold text-uppercase px-1 mt-1">
            {{ item.title }}
          </v-card-title>
          <!-- eslint-disable -->
          <v-card-text
            class="m-news-slider-summary body-2 px-1 pt-2 pb-0"
            v-html="item.content"
          />
          <!-- eslint-enable -->
          <v-card-actions>
            <v-btn
              disabled
              text
            >
              {{ item.date | formatDate('DD.MM.YYYY') }}
            </v-btn>
            <v-spacer />
            <v-btn
              text
              @click="activeItem = i"
            >
              Mehr...
            </v-btn>
          </v-card-actions>
        </v-card>
      </swiper-slide>
      <div
        slot="pagination"
        class="swiper-pagination primary-swiper-pagination"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
      <div
        slot="button-next"
        class="swiper-button-next"
      />
    </swiper>
    <v-dialog
      v-model="dialog"
      max-width="35em"
      scrollable
    >
      <v-card
        v-if="selectedItem"
        :color="dialogColor"
        :dark="dialogDark"
      >
        <v-card-title class="headline text-uppercase font-weight-bold d-flex justify-space-between align-start flex-nowrap" style="white-space: pre-line;">
          {{ selectedItem.title }}
          <v-btn
            fab
            text
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="ml-1">
          {{ selectedItem.date | formatDate('DD.MM.YYYY') }}
        </v-card-subtitle>
        <!-- eslint-disable-next-line -->
        <v-card-text style="white-space: pre-line;">
          {{ selectedItem.content }}
        </v-card-text>
        <!--<v-card-actions>
          <v-spacer />
          <v-btn @click="speak(selectedItem.content)">
            Speak
          </v-btn>
        </v-card-actions>-->
      </v-card>
    </v-dialog>
  </m-widget-container>
</template>

<script>
import { mWidgetMixin } from '../../../mixins'
export default {
  name: 'MNewsSlider',
  mixins: [mWidgetMixin],
  props: {
    title: {
      type: String,
      default: () => 'News Title'
    },
    items: {
      type: Array,
      default: () => []
    },
    cardColor: {
      type: String,
      default: () => null
    },
    cardDark: {
      type: Boolean,
      default: () => false
    },
    cardShaped: {
      type: Boolean,
      default: () => false
    },
    cardFlat: {
      type: Boolean,
      default: () => false
    },
    dialogColor: {
      type: String,
      default: () => null
    },
    dialogDark: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    dialog: false,
    activeItem: null,
    swiperOptions: {
      slidesPerView: 3,
      slidesPerGroup: 1,
      autoplay: {
        delay: 5000
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  }),
  computed: {
    selectedItem() {
      return this.activeItem !== null ? this.items[this.activeItem] : null
    }
  },
  watch: {
    selectedItem() {
      this.dialog = this.selectedItem !== null
    },
    dialog(newValue) {
      if (!newValue) {
        this.activeItem = null
      }
    }
  }
}
</script>
<style lang="sass">
  .border-box
    box-sizing: border-box
  .m-news-slider-title
    height: 4rem
    line-height: 1.5rem!important
    padding: 1rem
    margin: 0
    display: flex
    align-items: flex-start
    white-space: normal
    overflow: hidden
  .m-news-slider-summary
    height: 15.5rem
    white-space: normal
    overflow: hidden
    > h5:first-child, p:nth-child(2)
      display: none
    p
      margin-bottom: 1.25rem
    strong
      font-weight: 400
</style>
